import React from "react"

import Programm from "../components/programm"
import Image from "../components/image"

const IndexPage = () => (
  <div
    style={{width: 800}}>
    <Image />
    <Programm/>
  </div>

)

export default IndexPage
